
export const about = require('./about.png');
export const contactImg = require('./contactImg.png');
export const css = require('./css.png');
export const figma = require('./figma.png');
export const figmatwo = require('./figmatwo.png');
export const github = require('./github.png');
export const html = require('./html.png');
export const javascript = require('./javascript.png');
export const laptop = require('./laptop.png');
export const linkedin = require('./linkedin.png');
export const mylogo = require('./mylogo.png');
export const mysql = require('./mysql.png');
export const profileImg = require('./profileImage.jpg');
export const projects = require('./projects.png');
export const python = require('./python.png');
export const react = require('./react.png');
export const sass = require('./sass.png');
export const resume = require('./resume.png');
export const send = require('./send.png');
export const starstar = require('./starStarLogo.png');
export const strayPaws = require('./strayPawsLogo.png');
export const website = require('./web.png');








